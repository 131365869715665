import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Countdown from './countdown'; // Your Countdown component

// Define the fade-in animation for the modal content only
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// Define the fade-out animation for the modal content only
const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

// Define blur-in and blur-out keyframes for the background
const blurIn = keyframes`
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(8px);
  }
`;

const blurOut = keyframes`
  from {
    backdrop-filter: blur(8px);
  }
  to {
    backdrop-filter: blur(0px);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: ${(props) => (props.isClosing ? 'blur(0px)' : 'blur(8px)')};
  animation: ${(props) => (props.isClosing ? blurOut : blurIn)} 0.5s ease;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 25px 45px; /* Less top padding, more horizontal padding */
  border-radius: 16px; /* Increase border radius for more rounded corners */
  max-width: 600px; /* Increase the width to make the modal more rectangular */
  text-align: center;
  border: 5px solid #8B3419; /* Modal border color */
  animation: ${(props) => (props.isClosing ? fadeOut : fadeIn)} 0.5s ease;
`;

const StyledHeading = styled.h2`
  font-family: 'Poppins', sans-serif;
  color: #E5833A; /* Heading text color */
  font-weight: bold;
  font-size: 36px; /* Adjust to match the size of the text in the image */
  margin-bottom: 20px;
  margin-top: 0; /* Reduce the top margin to remove excess space */
`;

const StyledButton = styled.button`
  background-color: #E5833A;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  color: white;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 
              0px 8px 10px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: #8B3419;
  }

  &:active {
    background-color: #8B3419;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 
                0px 4px 5px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
  }
`;

const CountdownModal = ({ onClose }) => {
  const [isClosing, setIsClosing] = useState(false);

  // Handle closing with a fade-out effect
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose(); // Call the onClose prop after the animation is done
    }, 500); // Duration should match the animation time
  };

  return (
    <ModalOverlay isClosing={isClosing}>
      <ModalContent isClosing={isClosing}>
        <StyledHeading>Countdown</StyledHeading>
        <Countdown />
        <StyledButton onClick={handleClose}>Chiudi</StyledButton> {/* Styled close button */}
      </ModalContent>
    </ModalOverlay>
  );
};

export default CountdownModal;
