import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const CircleContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.svg`
  position: absolute;
  transform: rotate(-90deg);
`;

const CircleBackground = styled.circle`
  fill: none;
  stroke: #ddd;
  stroke-width: 6;
`;

const CircleProgress = styled.circle`
  fill: none;
  stroke: #E5833A;
  stroke-width: 6;
  stroke-dasharray: 377;
  stroke-dashoffset: ${(props) => props.offset};
  transition: stroke-dashoffset 1s linear;
`;

const TimeValue = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

const calculateTimeLeft = () => {
  const countdownDate = new Date('2024-09-30T23:59:59+02:00').getTime(); 
  const now = new Date().getTime();
  const difference = countdownDate - now;

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  } else {
    timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  return timeLeft;
};

const getCircleOffset = (currentValue, totalValue) => {
  const circumference = 377;
  return circumference - (currentValue / totalValue) * circumference;
};

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <CountdownContainer>
      <CircleContainer>
        <Circle width="150" height="150">
          <CircleBackground cx="75" cy="75" r="60" />
          <CircleProgress
            cx="75"
            cy="75"
            r="60"
            offset={getCircleOffset(timeLeft.days, 30)}
          />
        </Circle>
        <TimeValue>{timeLeft.days} Giorni</TimeValue>
      </CircleContainer>

      <CircleContainer>
        <Circle width="150" height="150">
          <CircleBackground cx="75" cy="75" r="60" />
          <CircleProgress
            cx="75"
            cy="75"
            r="60"
            offset={getCircleOffset(timeLeft.hours, 24)}
          />
        </Circle>
        <TimeValue>{timeLeft.hours} Ore</TimeValue>
      </CircleContainer>

      <CircleContainer>
        <Circle width="150" height="150">
          <CircleBackground cx="75" cy="75" r="60" />
          <CircleProgress
            cx="75"
            cy="75"
            r="60"
            offset={getCircleOffset(timeLeft.minutes, 60)}
          />
        </Circle>
        <TimeValue>{timeLeft.minutes} Minuti</TimeValue>
      </CircleContainer>

      <CircleContainer>
        <Circle width="150" height="150">
          <CircleBackground cx="75" cy="75" r="60" />
          <CircleProgress
            cx="75"
            cy="75"
            r="60"
            offset={getCircleOffset(timeLeft.seconds, 60)}
          />
        </Circle>
        <TimeValue>{timeLeft.seconds} Secondi</TimeValue>
      </CircleContainer>
    </CountdownContainer>
  );
};

export default Countdown;
