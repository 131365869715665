import React, { useState } from 'react';
import Button from '@mui/material/Button'; // Material UI Button
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Import clock icon (AccessTime)
import CountdownModal from './_components/countdownModal.jsx';
import styled from 'styled-components';
import style from './style.css';

// Wrapper to center the button
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: absolute;
  width: 100%;
  top: 20px;
`;

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ButtonWrapper>
        <Button
          variant="contained"
          onClick={openModal}
          sx={{
            backgroundColor: '#8B3419', // Custom background color
            borderRadius: '8px',
            fontFamily: 'Poppins',
            fontSize: '16px',
            padding: '12px 24px',
            '&:hover': {
              backgroundColor: '#722c15', // Darken the color on hover
            },
          }}
          startIcon={<AccessTimeIcon />} // Adding the clock icon
        >
          Countdown
        </Button>
      </ButtonWrapper>
      {isModalOpen && <CountdownModal onClose={closeModal} />}
    </>
  );
};

export default App;
